import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import "photoswipe/dist/photoswipe.css";
import { Message } from "types/Message";
import truncateString from "common/utils/truncateString";
import { useAuth } from "common/hooks/useAuth";
import Avatar from "common/components/Avatar/Avatar";
import checkRights from "common/utils/checkRights";
import { UserType } from "types/UserType";
import CommentFooter from "./CommentFooter";
import ThreadGallery from "../ThreadGallery/ThreadGallery";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface ICommentItem {
  comment: Message;
  onAdd?: () => void;
  compact?: boolean;
  title?: string;
  isPrivate?: boolean;
  selected?: boolean;
  canComment?: boolean;
  saveComment?: (ref: any) => void;
  siteId?: number;
  threadUserType?: UserType;
}

const getBubbleStyle = (isPrivate: boolean | undefined, isAuthor: boolean) => {
  if (isPrivate && isAuthor) {
    // bg-primary text-white rounded-lg  p-2
    return "bg-primary text-white rounded-lg p-2 text-right";
  }
  if (isPrivate) {
    // bg-primary text-white rounded-lg  p-2
    return "";
  }
  if (isAuthor) {
    return "bg-primary text-white text-right p-2 rounded-lg";
  }

  // return "bg-slate-100";
  return "bg-transparent";
};

function CommentItem({
  comment,
  compact,
  title,
  onAdd,
  isPrivate,
  selected,
  canComment = false,
  saveComment,
  siteId,
  threadUserType,
}: ICommentItem) {
  const { id, content, createdAt, author, isToxic } = comment;
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();
  const addRef = useRef<HTMLInputElement>(null);
  const name = author?.name || t("threads.unknownUser");
  const userData = useMemo(
    () =>
      checkRights(
        currentUser,
        "canSeeUserData",
        siteId ? [siteId] : undefined,
        ["isAdmin"],
        true
      )
        ? { email: author?.email, userType: threadUserType }
        : undefined,
    [author?.email, threadUserType, currentUser, siteId]
  );
  const privateMessages = get(comment, "privateThreadIntern.messages");

  const isAuthor = author?.id === currentUser?.id;
  const alignStyle = isAuthor ? "items-end" : "items-start";
  const bubbleStyle = getBubbleStyle(isPrivate, isAuthor);
  const dateStyle = !isAuthor ? "-bottom-2 right-10" : "-bottom-2 left-10";
  const authorStyle = !isAuthor ? "flex-row" : "flex-row-reverse";
  const addStyle = selected
    ? "pr-2 border-secondary-300 bg-secondary-200 text-secondary"
    : "";

  const containerStyle = selected ? "bg-secondary-100/80" : "";

  const displayFooter =
    (!canComment && privateMessages?.length > 0) || canComment;

  useEffect(() => {
    if (saveComment) {
      saveComment(addRef);
    }
  }, [saveComment]);

  const getAvatar = useCallback(
    (username: string, avatar: any) => (
      <Avatar name={username} image={avatar} />
    ),
    []
  );

  const parseMentions = (text: string) => {
    // Expression régulière
    const pattern = /@\[(.*?)\]/g;

    // Remplacement avec balises <strong>
    return text.replace(pattern, (match, toto) => {
      return `<span class="mention">@${toto}</span>`;
    });
  };

  const contents = (content as string)?.split("\n");

  return (
    <div
      onClick={
        onAdd && !isPrivate // && displayFooter
          ? onAdd
          : undefined
      }
      className={`w-full text-slate-900/70 mb-1 ${
        isPrivate ? "" : "hover:bg-secondary-100/80 rounded-lg"
      }
      ${onAdd && displayFooter && !isPrivate ? "cursor-pointer" : ""}
       ${containerStyle}`}
    >
      <div className={`flex flex-col ${alignStyle} ${isPrivate ? "" : "p-2"}`}>
        {title && (
          <div className="flex items-center text-xs gap-1 mb-1 text-secondary justify-end">
            {title}
          </div>
        )}
        <TooltipWrapper
          className="mr-1 my-1"
          content={
            userData ? (
              <div>
                <div>{userData.email}</div>
                {userData.userType && (
                  <div>Rôle: {userData?.userType?.name}</div>
                )}
              </div>
            ) : (
              <div>{name}</div>
            )
          }
        >
          <div className={`flex items-center ${authorStyle} flex gap-2`}>
            {getAvatar(author?.name, author?.avatar)}
            <div className="font-bold">{name}</div>
            <div className={`italic text-xs text-black/60 ${dateStyle}`}>
              {dayjs(createdAt).fromNow()}
            </div>
          </div>
        </TooltipWrapper>

        <div className={`w-full my-2 flex flex-col ${alignStyle}`} key={id}>
          {(content as string)?.length > 0 && (
            <div className={bubbleStyle}>
              {compact ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: parseMentions(
                      truncateString(content as string, 50)
                    ),
                  }}
                />
              ) : (
                contents?.map((p: string) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p
                    className="mb-2"
                    key={`message-${id}-${createdAt}`}
                    dangerouslySetInnerHTML={{ __html: parseMentions(p) }}
                  />
                ))
              )}
            </div>
          )}
          <div className="w-full">
            <ThreadGallery
              documents={comment.documents}
              exifData={comment.exifData}
              isPrivate={isPrivate}
            />
          </div>
        </div>
        {isToxic ? (
          <div className="text-red-500 w-full text-right">
            ⚠ Propos incorrect détecté
          </div>
        ) : null}
      </div>

      <div
        // onClick={onAdd}
        className={`group flex items-center justify-end border-slate-200  py-1 cursor-pointer 
          hover:pr-2 border-b  border-transparent h-[35px] rounded-bl-lg
          ${addStyle} transition-all`}
        ref={addRef}
      >
        {onAdd && displayFooter && !isPrivate && (
          <CommentFooter privateMessages={privateMessages} />
        )}
      </div>
    </div>
  );
}

export default CommentItem;
